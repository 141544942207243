<template>
  <el-cascader
    v-model="selectValue"
    :options="options"
    :props="optionsPros"
    @change="change"
  ></el-cascader>
</template>

<script>
import {getAllChildrenList} from '@/api'
export default {
  name: 'index.vue',
  async created () {
    try{
      const res = await getAllChildrenList({
        type:3
      });
      this.options = res.data.data.sentry;
      this.options.unshift({
        name:"全部",
        id:"all"
      })
    }catch (e) {
    }
  },
  data(){
    return {
      options:[],
      selectValue:"",
      optionsPros:{
        value:'id',
        label:'name'
      }
    }
  },
  methods:{
    change(){
      this.$emit("selectValue",this.selectValue)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
  width: 200px;
}
</style>
