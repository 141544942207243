<template>
  <div class="wrapper-data">
    <div class="header">
      <div class="heade-date">
        <el-date-picker
          v-model="dateValue"
          type="daterange"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="heade-option">
        <selection @selectValue="getSelectVal" :isDeep="true"></selection>
        <el-button @click="selectStatis">查询</el-button>
        <el-button @click="exportData">导出</el-button>
      </div>
    </div>
    <div class="content">
      <div class="cardlist">
        <report-card
          class="card"
          v-for="(item, index) in boxList"
          :item="item"
          :key="index"
        ></report-card>
      </div>
    </div>
  </div>
</template>

<script>
import Selection from "@/components/Selection";
import { reportStatistics } from "@/api";
import ReportCard from "./components/reportCard";
import { exportReports } from "@/api";
import { exportFun } from "@/utils/params";
import RightContent from "../commonViews/RightContent.vue";
export default {
  name: "data-statisical",
  components: {
    ReportCard,
    Selection,
    RightContent,
  },
  async created() {
    await this.render();
  },
  data() {
    return {
      selectValue: "",
      selectArr: [],
      reportInfo: "",
      options1: [],
      options2: [],
      options3: [],
      dateValue: "",
      boxList:[
        {
          title:"监督岗报事数量统计",
          content:"",
          icon:require("@/assets/images/reporttj.png")
        },
        {
          title:"处理中报事数量",
          content:"",
          icon:require("@/assets/images/reportnum.png")
        },
        {
          title:"已完成总数量",
          content:"",
          icon:require("@/assets/images/finish.png")
        },
        {
          title:"超时未处理报事数",
          content:"",
          icon:require("@/assets/images/outtime.png")
        },
        {
          title:"发现隐患总数",
          content:"",
          icon:require("@/assets/images/yinghuan.png")
        },
        {
          title:"排查隐患(处)",
          content:"",
          icon:require("@/assets/images/found.png")
        },
        {
          title:"发现重大隐患(处)",
          content:"",
          icon:require("@/assets/images/zhongda.png")
        },
        {
          title:"排查重大隐患(处)",
          content:"",
          icon:require("@/assets/images/paichu.png")
        },
        {
          title:"减少经济损失总额(万元)",
          content:"",
          icon:require("@/assets/images/shunshi.png")
        }
      ],
      options: [],
    };
  },
  methods: {
    exportData() {
      const param = {
        type: 3,
        sentry_id: this.selectValue[0] || '',
        department_id: localStorage.getItem("department_id"),
      };
      exportFun(exportReports, param, "exportReports");
    },

    getSelectVal(val) {
      val[0] !== 'all' ? this.selectValue = val :  this.selectValue = "" ;
    },
    async render(requestData = {
      department_id:localStorage.getItem("department_id")
    }) {
      const res = await reportStatistics(requestData);
      const reportInfo = res.data.data;
      Object.keys(reportInfo).forEach((key, index) => {
        this.boxList[index].content = reportInfo[key];
      });
    },
    async selectStatis() {
      const requestData = {
        sentry_id: this.selectValue[0],
        department_id: localStorage.getItem("department_id")
      };
      if(this.dateValue && this.dateValue.length) {
        requestData.begin_time = this.dateValue[0]
        requestData.end_time = this.dateValue[1]
      }
      await this.render(requestData)
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-data {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
  .header {
    display: flex;
    margin-bottom: 30px;
    button {
      margin-left: 15px;
      background-color: red;
      color: #fff;
    }
  }
  .content {
    flex: 1;
    .cardlist {
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      margin-right: -1%;
      .card {
        width: 19%;
        margin-right: 1%;
      }
    }
  }
}
.heade-option {
  margin-left: 20px;
}

::v-deep .el-icon-date {
  color: red;
  font-size: 20px;
}
</style>
